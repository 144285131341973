import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import arrow from '../assets/images/arrow-up.svg'
const ProjectsComponent = ({ title, index }) => {
    const [isShown, setIsShown] = useState('opacity-0');
    const [border, setBorder] = useState('border-none');
    const showInfo = (e) => {
        e.preventDefault();
        if (isShown == 'opacity-0') setIsShown('opacity-100')
        else setIsShown('opacity-0');
        if (border == 'border-none') setBorder('border-2')
        else setBorder('border-none');
    }
    if (title === 'Architecture design')
        return (
            <Link to={'/projects/architecture'}>
                <div className={`project project-${index} h-[500px] flex flex-col items-start justify-end  cursor-pointer transition-all ease-in-out duration-300 relative`}>
                    <div className='flex w-full justify-between pb-3 px-4'>
                        <h3 className='uppercase text-light text-left font-bold leading-5 z-30'>{title} <br></br> projects</h3>
                        <img src={arrow} onClick={(e) => showInfo(e)} className="z-30"></img>
                    </div>
                    <div className={`text-light absolute w-full bg-[#222222] h-full border border-primary ${isShown} transition-all duration-[750ms] ease-in-out p-4 pb-3`}>
                        Our team of skilled architects works closely with clients to develop and refine concepts, using a variety of techniques such as sketching, modeling, and computer-aided design (CAD) software. We also collaborate with a team of engineers, contractors, and other professionals to ensure that the final design meets all necessary technical, safety, and aesthetic requirements.
                    </div>
                </div>
            </Link>

        )
    else if (title === 'Interior design')
        return (
            <Link to={'/projects/interior'}>
                <div className={`project project-${index} h-[500px] flex flex-col items-start justify-end  cursor-pointer transition-all ease-in-out duration-300 relative`}>
                    <div className='flex w-full justify-between pb-3 px-4'>
                        <h3 className='uppercase text-light text-left font-bold leading-5 z-30'>{title} <br></br> projects</h3>
                        <img src={arrow} onClick={(e) => showInfo(e)} className="z-30"></img>
                    </div>
                    <div className={`text-light absolute w-full bg-[#222222] h-full border border-primary ${isShown} transition-all duration-[750ms] ease-in-out p-4 pb-3`}>
                        Our team of experienced interior designers works closely with clients to understand their needs and preferences, and to develop a design plan that reflects their personal style and meets their functional requirements. From conceptualization to execution, we guide our clients through every step of the interior design process, ensuring that the final result is a cohesive and harmonious space that enhances the overall aesthetic and functionality of the home or commercial space.
                    </div>
                </div>
            </Link>
        )
    else if (title === 'Construction')
        return (
            <Link to={'/projects/construction'}>
                <div className={`project project-${index} h-[500px] flex flex-col items-start justify-end  cursor-pointer transition-all ease-in-out duration-300 relative`}>
                    <div className='flex w-full justify-between pb-3 px-4'>
                        <h3 className='uppercase text-light text-left font-bold leading-5 z-30'>{title} <br></br> projects</h3>
                        <img src={arrow} onClick={(e) => showInfo(e)} className="z-30"></img>
                    </div>
                    <div className={`text-light absolute w-full bg-[#222222] h-full border border-primary ${isShown} transition-all duration-[750ms] ease-in-out p-4 pb-3`}>
                        Our team of professionals includes architects, engineers, and contractors who work collaboratively to ensure that the construction process runs smoothly and efficiently. We use the latest technology and project management tools to stay organized and on schedule, and we maintain open lines of communication with our clients to keep them informed of progress and address any concerns they may have.
                    </div>
                </div>
            </Link>
        )
}

export default ProjectsComponent