import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation, useRoutes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Header from './essentials/Header';
import Projects from './pages/Projects';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import SingleProject from './pages/SingleProject';
import Interior from './pages/Interior';
import About from './pages/About';
import Architecture from './pages/Architecture';

export default function App() {
  const element = useRoutes([
    {
      path: "/",
      element: <Homepage />
    },
    {
      path: "/projects",
      element: (
        <Projects />
      )
    },
    {
      path: "/project/:project_id",
      element: (
        <SingleProject />
      )
    },
    {
      path: '/projects/architecture',
      element: (
        <Architecture></Architecture>
      )
    },
    {
      path: '/projects/interior',
      element: (
        <Interior></Interior>
      )
    },
    {
      path: '/projects/construction',
      element: (
        <Interior></Interior>
      )
    },
    {
      path: '/about',
      element: (
        <About></About>
      )
    }
  ]);

  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait" initial={false}>
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}


