import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import show from '../assets/images/show.svg'
const Project = ({ title, index }) => {
    const [isShown, setIsShown] = useState('opacity-0');
    return (
        <Link to={'/projects/interior'}>
            <div className={`project project-${index} h-[500px] flex flex-col items-start justify-end p-4 pb-3 cursor-pointer transition-all ease-in-out duration-300 relative`} onMouseEnter={() => setIsShown('opacity-100')} onMouseLeave={() => setIsShown('opacity-0')} onTouchStart={() => setIsShown('opacity-100')} onTouchEnd={() => setIsShown('opacity-0')} >
                <h3 className='uppercase text-light text-left font-bold leading-5'>{title} <br></br> projects</h3>
                <img src={show} width="75px" className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all ease-in-out duration-300 ${isShown}`}></img>
            </div>
        </Link>
    )
}

export default Project