import React, { useEffect, useState } from "react";
import close from "../assets/images/close.svg";
import logo from "../assets/images/rac-dark.svg";
import instagram from "../assets/images/instagram-dark.svg";
import facebook from "../assets/images/facebook-dark.svg";
import linkedin from "../assets/images/linkedin-dark.svg";
import whatsapp from "../assets/images/whatsapp-dark.svg";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const MobileNavigation = ({ open, cycleOpen }) => {
  const [hidden, setHidden] = useState("hidden");
  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  };
  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };
  useEffect(() => {
    if (open == true) {
      setHidden("block");
      document.body.style.overflow = "hidden";
    } else {
      setHidden("hidden");
      document.body.style.overflow = "auto";
    }
  }, [open]);
  return (
    <AnimatePresence>
      {open && (
        <motion.aside
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 10, opacity: 0, transition: { duration: 0.2 } }}
          className={` bg-primary fixed h-screen w-full top-0 p-4 z-[120]`}
        >
          <motion.div
            className="h-full"
            initial="closed"
            exit="closed"
            animate="open"
            variants={sideVariants}
          >
            <div className="flex justify-between items-center">
              <img src={logo}></img>
              <img src={close} width="20px" onClick={cycleOpen}></img>
            </div>
            <div className={`flex flex-col items-center justify-center h-full`}>
              <div className="flex flex-col items-center">
                <Link onClick={cycleOpen} to={"/"}>
                  <motion.p
                    variants={itemVariants}
                    className="uppercase cursor-pointer hover:text-light transition duration-200 ease-in-out text-xs font-bold"
                  >
                    Home
                  </motion.p>
                </Link>
                <div className="my-1"></div>
                <Link to={"/projects"} onClick={cycleOpen} className="mx-10">
                  <motion.p
                    variants={itemVariants}
                    className="uppercase cursor-pointer hover:text-light transition duration-200 ease-in-out text-xs font-bold"
                  >
                    projects
                  </motion.p>
                </Link>
                <div className=" my-1"></div>
                <Link onClick={cycleOpen} to={"/about"}>
                  <motion.p
                    variants={itemVariants}
                    className="uppercase cursor-pointer hover:text-light transition duration-200 ease-in-out text-xs font-bold"
                  >
                    About Us
                  </motion.p>
                </Link>
              </div>
              <div className="my-8"></div>
              <div className="flex justify-center items-center">
                <div className="">
                  <motion.img
                    variants={itemVariants}
                    src={facebook}
                    width="20px"
                  ></motion.img>
                </div>
                <div className="mx-2"></div>
                <div className="">
                  <motion.img
                    variants={itemVariants}
                    src={instagram}
                    width="20px"
                  ></motion.img>
                </div>
                <div className="mx-2"></div>
                <div className="">
                  <motion.img
                    variants={itemVariants}
                    src={linkedin}
                    width="20px"
                  ></motion.img>
                </div>
                <div className="mx-2"></div>
                <div className="">
                  <motion.img
                    variants={itemVariants}
                    src={whatsapp}
                    width="20px"
                  ></motion.img>
                </div>
              </div>
              <div className="my-4"></div>
              <motion.div variants={itemVariants} className="text-center">
                <motion.p
                  variants={itemVariants}
                  className=" hover:text-light transition duration-200 ease-in-out text-sm leading-4"
                >
                  00 961 81 0000 24
                </motion.p>
                <div className="mx-2"></div>
                <motion.p
                  variants={itemVariants}
                  className=" hover:text-light transition duration-200 ease-in-out text-sm"
                >
                  info@racengineeringlb.com
                </motion.p>
              </motion.div>
            </div>
          </motion.div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};

export default MobileNavigation;
