import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../assets/images/rac-dark.svg'
import instagram from '../assets/images/instagram-dark.svg'
import facebook from '../assets/images/facebook-dark.svg'
import linkedin from '../assets/images/linkedin-dark.svg'
import whatsapp from '../assets/images/whatsapp-dark.svg'
const Footer = () => {
    const [hidden, setHidden] = useState('block');
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('projects/')) setHidden('hidden');
        else setHidden('block');
    }, [location])

    return (
        <div className={`bg-[#AE8017]  ${hidden}`}>
            <div className={`w-full p-6 `}>
                <div className='flex flex-col lg:flex-row justify-between 2xl:container mx-auto items-center'>
                    <div className=''>
                        <img src={logo}></img>
                    </div>
                    <div className='lg:hidden block my-4'></div>
                    <div className='flex flex-col lg:flex-row items-center'>
                        <Link to={'/'}>
                            <p className='uppercase   cursor-pointer hover:text-light transition duration-200 ease-in-out text-xs'>Home</p>
                        </Link>
                        <div className='lg:hidden block my-0.5'></div>
                        <Link to={'/projects'} className="mx-10">
                            <p className='uppercase cursor-pointer hover:text-light transition duration-200 ease-in-out text-xs'>projects</p>
                        </Link>
                        <div className='lg:hidden block my-0.5'></div>
                        <Link to={'/about'}>
                            <p className='uppercase cursor-pointer hover:text-light transition duration-200 ease-in-out text-xs'>About Us</p>
                        </Link>
                    </div>
                    <div className='lg:hidden block my-4'></div>
                    <div className='flex items-center'>
                        <a href='https://www.facebook.com/RACengineering.lb/'>
                            <img src={facebook} width="20px"></img>
                        </a>
                        {/* <div className="mx-2"></div>
                        <img src={instagram} width="20px"></img>
                        <div className="mx-2"></div>
                        <img src={linkedin} width="20px"></img> */}
                        <div className="mx-2"></div>
                        <a href='https://wa.me/96181000024'>
                            <img src={whatsapp} width="20px"></img>
                        </a>
                    </div>

                </div>

            </div>
            <div className='bg-[#AE8017]'>
                <p className='text-[10px] uppercase text-center pb-6'>All Rights Reserved - 2022.</p>
            </div>
        </div>
    )
}

export default Footer