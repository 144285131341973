import axios from "axios";
const URL = "https://api.racengineeringlb.com";
// const URL = "http://localhost:8000";
const getAllProjects = async () => {
  try {
    const response = await axios.get(URL + "/api/getAllProjects");
    return response.data;
  } catch (e) {
    return e;
  }
};

const getAllEmployees = async () => {
  try {
    const response = await axios.get(URL + "/api/getAllEmployees");
    return response.data;
  } catch (e) {
    return e;
  }
};
const getProjectByID = async (project_id) => {
  try {
    const response = await axios.get(
      URL + "/api/getProjectByID?project_id=" + project_id,
      {
        headers: { Accept: "application/json" },
      }
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
const getImagesByProject = async (project_id) => {
  try {
    const response = await axios.get(
      URL + "/api/getImagesByProject?project_id=" + project_id
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
const getProjectsByType = async (type_id) => {
  try {
    const response = await axios.get(
      URL + "/api/getProjectsByType?type_id=" + type_id
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
export {
  getAllProjects,
  getProjectByID,
  getAllEmployees,
  getProjectsByType,
  getImagesByProject,
};
