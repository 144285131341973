import { motion, useIsPresent } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import story from '../assets/images/story.jpg'
import Employee from '../components/Employee';
import Loading from '../essentials/Loading';
import { getAllEmployees } from '../services/Api'
const About = () => {
    const isPresent = useIsPresent();
    // const [isLoading, setIsLoading] = useState(true);
    // const [employees, setEmployees] = useState([]);
    // const getData = async () => {
    //     setIsLoading(true);
    //     let response = await getAllEmployees();
    //     setEmployees(response);
    //     setIsLoading(false);
    // }
    // useEffect(() => {
    //     getData();
    // }, [])
    return (
        <div>
            <div className='about h-screen items-center'>
                <div className='2xl:container mx-auto flex flex-col justify-center items-start z-10 h-full'>
                    <h1 className='uppercase font-bold text-2xl md:text-3xl lg:text-4xl text-light px-4 text-left'>About us</h1>
                    {/* <div className='my-4'></div>
                    <p className='text-light px-4 max-w-[550px] text-sm lg:text-md text-left'>Lorem ipsum dolor sit amet consectetur. Lorem eu lorem tellus vitae consequat massa. Aliquam egestas nec sed nunc pellentesque sit et. Et dui adipiscing morbi tellus.</p> */}
                </div>
                <div className='invisible'></div>
            </div>
            <div className='my-[50px] lg:my-[100px]'></div>
            <div className='2xl:container mx-auto'>
                <div className='mx-4'>
                    <h1 className='uppercase font-bold text-[#CB9831] text-xl lg:text-3xl leading-6 lg:mt-0 mt-8'>our story</h1>
                    <p className='text-light text-justify mt-4'>At RAC, we believe in taking a comprehensive approach to each project we undertake. This involves a design and construction process that blends the technical expertise, dedication, and experience of our team of professionals. Our team is passionate about delivering top-quality results for our clients and consistently strives to exceed their expectations. We take pride in our ability to provide exceptional service and are committed to delivering the best possible outcome for every project. We look forward to the opportunity to work with you and bring your vision to life. </p>

                    <div className='my-12'></div>
                    <div className='grid lg:grid-cols-2 w-full'>
                        <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-12 items-center order-last lg:order-1'>
                            <div className=''>
                                <h1 className='uppercase font-bold text-[#CB9831] text-lg lg:text-2xl leading-3 lg:mt-0 mt-8'>01.</h1>
                                <p className='text-[#CB9831] uppercase  lg:leading-3'>Who are we?</p>
                                <div className='my-2'></div>
                                <p className='text-light text-sm'>We are a team of highly skilled and passionate architects and engineers, dedicated to creating innovative and functional designs that enhance the built environment. With a diverse range of backgrounds and experience, our team is equipped to handle projects of all sizes and styles, from residential to commercial to institutional.</p>
                            </div>

                            <div className=''>
                                <h1 className='uppercase font-bold text-[#CB9831] text-lg lg:text-2xl leading-3 lg:mt-0 mt-8'>02.</h1>
                                <p className='text-[#CB9831] uppercase  lg:leading-3'>What do we do?</p>
                                <div className='my-2'></div>
                                <p className='text-light text-sm'>We offer a wide range of services to bring our clients' vision to life. This includes initial concept development and design, through to construction documentation and project management. We also have extensive experience in sustainable design and can incorporate energy-efficient and eco-friendly elements into our projects.</p>
                            </div>

                            <div className=''>
                                <h1 className='uppercase font-bold text-[#CB9831] text-lg lg:text-2xl leading-3 lg:mt-0 mt-8'>03.</h1>
                                <p className='text-[#CB9831] uppercase  lg:leading-3'>How do we help?</p>
                                <div className='my-2'></div>
                                <p className='text-light text-sm'>Whether you need help with the design of a new residential property, commercial building, or public space, we have the skills and expertise to deliver exceptional results. In addition to our design services, we also offer expert advice on incorporating aesthetics and functionality into a space based on the needs and audience of our clients.</p>
                            </div>


                        </div>
                        <div className='flex flex-col justify-end items-end lg:order-last order-1'>
                            <img src={story} className="text-right lg:w-3/4"></img>
                        </div>
                    </div>
                </div>

            </div>
            <div className='my-[50px] lg:my-[100px]'></div>
            {/* <div className='2xl:container mx-auto'>
                <div className='mx-4'>
                    <h1 className='uppercase font-bold text-[#CB9831] text-xl lg:text-3xl leading-6 lg:mt-0 mt-8'>Meet  the  Team</h1>
                    {isLoading ? <Loading></Loading> :
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-4'>
                            {

                                employees.map((data) => (
                                    <Employee lastName={data.employee_last_name} firstName={data.employee_first_name} position={data.employee_position}></Employee>
                                ))
                            }
                        </div>
                    }
                </div>
            </div> */}
            {/* <div className='my-[50px] lg:my-[100px]'></div> */}
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen flex flex-col justify-center h-full"
            />
        </div>
    )
}

export default About