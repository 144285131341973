import React, { useState } from 'react'
import { motion, useIsPresent } from "framer-motion"
import arrow from '../assets/images/arrow-up.svg'
import Project from '../components/Project';
import show from '../assets/images/show.svg'
import ProjectsComponent from '../components/ProjectsComponent';
const Projects = () => {
    const isPresent = useIsPresent();
    const [isShown, setIsShown] = useState('opacity-0');
    return (
        <>
            <div className='projects h-screen grid grid-cols-1 lg:grid-cols-2  items-center'>
                <div className='hidden lg:invisible lg:block'></div>
                <div className='flex flex-col items-start z-10'>
                    <h1 className='uppercase font-bold text-2xl md:text-3xl lg:text-4xl text-light px-4 text-left'>OUR PROJECTS</h1>
                    <div className='my-4'></div>
                    <p className='text-light px-4 max-w-[550px] text-left text-sm lg:text-md'>From Concept to Completion: Our Completed Projects and Built Designs, Showcasing Our Ability to Bring Visions to Life</p>
                </div>
            </div>

            <div className='my-[50px] lg:my-[100px]'></div>

            <div className='2xl:container mx-auto '>
                <div className='grid lg:gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 h-full'>
                    {[{ title: 'Architecture design', type: 'architecture' }, { title: 'Interior design', type: 'interior' }, { title: 'Construction', type: 'construction' }].map((data, index) => (
                        <ProjectsComponent type={data.type} title={data.title} index={index + 1}></ProjectsComponent>
                    ))}
                </div>
            </div>
            <div className='my-[50px] lg:my-[100px]'></div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen flex flex-col justify-center h-full"
            />

        </>
    )
}

export default Projects