import React, { useRef, useState } from 'react'

import image_1 from '../assets/images/image-1.jpg'
import image_2 from '../assets/images/image-2.jpg'
import interior from '../assets/images/interior.svg'
import furniture from '../assets/images/furniture.svg'
import consulting from '../assets/images/consulting.svg'
import architecture from '../assets/images/architecture.svg'
import ig1 from '../assets/images/instagram-1.jpg'
import ig2 from '../assets/images/instagram-2.jpg'
import ig3 from '../assets/images/instagram-3.jpg'
import ig4 from '../assets/images/instagram-4.jpg'
import { motion, useIsPresent } from "framer-motion"
import Project from '../components/Project'
const Homepage = () => {
    const isPresent = useIsPresent();
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" })
    return (
        <div>
            <div className='landing h-screen flex flex-col items-center justify-center'>
                <div className='flex flex-col items-center z-10'>
                    <h1 className='uppercase font-bold text-2xl md:text-3xl lg:text-4xl text-light px-4 text-center'>Constructing your dreams</h1>
                    <div className='my-6'></div>
                    <p className='text-light px-4 max-w-[750px] text-center text-sm lg:text-md'>At RAC, we specialize in bringing our clients' architectural and interior design dreams to life. We are dedicated to providing top-quality service and working closely with our clients to ensure that their vision is realized. Let us help you design and construct the space of your dreams.</p>
                    <div className='my-6'></div>
                    <div className='p-2 px-4 uppercase border-2 dark:!border-[#CB9831] border-[#CB9831] font-bold w-auto text-[#CB9831] btn btn-1 btn-1e' onClick={executeScroll}>Learn more</div>
                </div>
            </div>

            <div className='h-[50px] lg:h-[100px]' ref={myRef}></div>

            <div className='2xl:container mx-auto' >
                <div className='grid grid-cols-1 lg:grid-cols-2 mx-4 items-center'>
                    <div className='flex flex-col w-full items-start'>
                        <div className='flex flex-row lg:flex-col xl:flex-row justify-between lg:mx-12'>
                            <img src={image_1} className='lg:w-5/6 xl:w-[48%] w-[48%]' />
                            <div className='mx-1 my-1'></div>
                            <img src={image_2} className='lg:w-5/6 xl:w-[48%] w-[48%]' />
                        </div>
                        {/* <div className='my-2'></div> */}
                        {/* <div className='bg-primary p-4 text-left w-full'>
                            <p className='leading-5'>Watch a video to see how we work and try to deliver the best for our customers.</p>
                            <div className='my-2'></div>
                            <div className='underline text-xs font-bold cursor-pointer w-fit'>Play Video</div>
                        </div> */}
                    </div>
                    {/* <div className='mx-0 lg:mx-12'></div> */}
                    <div className='flex flex-col justify-center text-left my-4 w-full lg:px-12'>
                        <div className=''>
                            <h4 className='uppercase font-bold text-light leading-5 text-xs'>who are we?</h4>
                            <h1 className='uppercase font-bold text-primary text-xl lg:text-2xl leading-6'>architectural elegance</h1>
                        </div>
                        <div className='my-3'></div>
                        <div className='max-w-xl text-light text-justify text-sm lg:text-md'>
                            Our company is a renowned architectural and interior design firm based in Lebanon since 2015. Our team specializes in both residential and commercial sectors and has a proven track record of delivering spaces that meet the unique needs of our clients, reflecting their identity and personal style. We pride ourselves on our ability to provide exceptional service to each and every one of our clients and look forward to the opportunity to work with you on your next project.
                        </div>
                        {/* <div className='my-5'></div> */}
                        {/* <div className='underline text-xs font-bold cursor-pointer w-fit text-primary'>Learn More</div> */}
                    </div>
                </div>
            </div>

            <div className='my-[50px] lg:my-[100px]'></div>

            <div className='2xl:container mx-auto'>
                <div className='mx-4 flex-col items-start text-left'>
                    <div className=''>
                        <h4 className='uppercase font-bold text-light leading-5 text-xs'>WHAT DO WE DO?</h4>
                        <h1 className='uppercase font-bold text-primary text-xl lg:text-2xl leading-6'>PROVIDING THE BEST ARCHITECTURAL SERVICES</h1>
                    </div>
                    <div className='my-4'></div>
                    <div className='grid gap-4 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1'>
                        <div className='border-2 border-primary border-opacity-10 p-5'>
                            <img src={architecture}></img>
                            <div className='my-3'></div>
                            <div className=''>
                                <h5 className='uppercase font-bold text-light'>architecture</h5>
                                {/* <p className='text-light text-sm mt-1'>We specialize in creating beautiful and functional designs for buildings of all shapes and sizes. Our team of experienced architects has a deep understanding of building design, structural engineering, and urban planning, and we work closely with our clients to ensure that their vision is brought to life. Whether you need help with the design of a new residential property, commercial building, or public space, we have the skills and expertise to deliver exceptional results. </p> */}
                                <p className='text-light text-sm mt-1'>Our team specializes in creating beautiful and functional designs for all types of buildings. We have a deep understanding of building design, engineering, and urban planning and work closely with clients to bring their vision to life. </p>
                            </div>
                        </div>

                        <div className='border-2 border-primary border-opacity-10 p-5'>
                            <img src={interior}></img>
                            <div className='my-3'></div>
                            <div className=''>
                                <h5 className='uppercase font-bold text-light'>interior design</h5>
                                {/* <p className='text-light text-sm mt-1'>We understand that your home or office is more than just a place to live or work – it's an extension of who you are. That's why we offer interior design services that help you create a space that not only looks beautiful, but also reflects your personal style and enhances your daily life. Our team of experienced designers will work with you to understand your needs and preferences, and will use their expertise to create a design that is functional, stylish, and unique to you. </p> */}
                                <p className='text-light text-sm mt-1'>Furniture design is an applied art that requires an understanding of materials and fabrication techniques, as well as a sense of function and balance. Our team ensures that the furniture we design reflects our clients' style and personality.
                                </p>
                            </div>
                        </div>

                        <div className='border-2 border-primary border-opacity-10 p-5'>
                            <img src={furniture}></img>
                            <div className='my-3'></div>
                            <div className=''>
                                <h5 className='uppercase font-bold text-light'>Construction</h5>
                                {/* <p className='text-light text-sm mt-1'>Building or renovating a property is a major investment, and we are dedicated to helping our clients make the most of their investment. Our team of experienced construction professionals will work with you to understand your vision and goals for your project, and will use their expertise to ensure that every detail is executed to perfection. From start to finish, we will be by your side to provide guidance, support, and exceptional service.</p> */}

                                <p className='text-light text-sm mt-1'>We offer expert advice on incorporating aesthetics and functionality into a space, based on the needs and audience of our clients in both residential and commercial settings.  </p>                            </div>
                        </div>

                        <div className='border-2 border-primary border-opacity-10 p-5'>
                            <img src={consulting}></img>
                            <div className='my-3'></div>
                            <div className=''>
                                <h5 className='uppercase font-bold text-light'>consulting</h5>
                                <p className='text-light text-sm mt-1'>We offer expert advice on incorporating aesthetics and functionality into a space based on the needs and audience of our clients, both in residential and commercial settings.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='my-[50px] lg:my-[100px]'></div>
            <div className='2xl:container mx-auto'>
                <div className='grid gap-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                    {['Architecture design', 'Interior design', 'Construction'].map((data, index) => (
                        <Project title={data} index={index + 1}></Project>
                    ))}
                </div>
            </div>

            <div className='my-[50px] lg:my-[100px]'></div>
            {/* <div className='2xl:container mx-auto'>
                <div className='flex flex-col lg:flex-row mx-4 items-center justify-around'>
                    <div className='flex flex-col justify-center text-left my-4  '>
                        <div className='mx-auto'>
                            <h4 className='uppercase font-bold text-light leading-5 text-xs'>Social</h4>
                            <h1 className='uppercase font-bold text-primary text-xl lg:text-2xl leading-6'>OUR LATEST INSTAGRAMS</h1>
                        </div>
                    </div>

                    <div className='flex flex-col items-start'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                            <img src={ig1} className="w-full lg:w-[275px]" ></img>
                            <img src={ig2} className="w-full lg:w-[275px]"></img>
                            <img src={ig3} className="w-full lg:w-[275px]"></img>
                            <img src={ig4} className="w-full lg:w-[275px]"></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className='my-[100px]'></div> */}
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    )
}

export default Homepage