import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/rac_logo.svg";
import instagram from "../assets/images/instagram.svg";
import facebook from "../assets/images/facebook.svg";
import linkedin from "../assets/images/linkedin.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import menu from "../assets/images/menu.svg";
import MobileNavigation from "../components/MobileNavigation";
import { useCycle } from "framer-motion";
const Header = () => {
  const [hiddenNavigation, setHiddenNavigation] = useState("hidden");
  const [open, cycleOpen] = useCycle(false, true);
  return (
    <div className="left-1/2 -translate-x-1/2 absolute border-b border-b-gray-400 w-full z-50">
      <div className="bg-transparent w-full mx-auto  p-3 lg:p-4 flex-row justify-between items-center text-xs flex  z-10 2xl:container ">
        <div className="w-[150px]">
          <img src={logo} className="md:w-[100px] w-[50px]"></img>
        </div>
        <div className="lg:flex flex-row items-center hidden">
          <Link to={"/"}>
            <p className="uppercase text-light font-bold cursor-pointer hover:text-[#E3AA26] transition duration-200 ease-in-out">
              Home
            </p>
          </Link>
          <Link to={"/projects"} className="mx-10">
            <p className="uppercase text-light font-bold  cursor-pointer hover:text-[#E3AA26] transition duration-200 ease-in-out">
              projects
            </p>
          </Link>
          <Link to={"/about"}>
            <p className="uppercase text-light font-bold cursor-pointer hover:text-[#E3AA26] transition duration-200 ease-in-out">
              About Us
            </p>
          </Link>
        </div>

        <div className="lg:flex items-center hidden">
          <a
            href="tel:+96181000024"
            className="text-light hover:text-[#E3AA26] transition duration-200 ease-in-out cursor-pointer"
          >
            00 961 81000024
          </a>
          <div className="mx-2"></div>
          <a
            href="mailto:info@racengineeringlb.com"
            className="text-light hover:text-[#E3AA26] transition duration-200 ease-in-out cursor-pointer"
          >
            info@racengineeringlb.com
          </a>
          <div className="mx-2"></div>
          <div className="flex items-center">
            <a href="https://www.facebook.com/RACengineering.lb/" className="">
              <img src={facebook} width="15px"></img>
            </a>
            <div className="mx-1"></div>
            {/* <div className=''>
                            <img src={instagram} width="15px"></img>
                        </div> */}
            {/* <div className='mx-1'></div> */}
            {/* <div className=''>
                            <img src={linkedin} width="15px"></img>
                        </div> */}
            {/* <div className='mx-1'></div> */}
            <a href="https://wa.me/96181000024" className="">
              <img src={whatsapp} width="15px"></img>
            </a>
          </div>
        </div>

        <div className="lg:hidden" onClick={cycleOpen}>
          <img src={menu} className="" width="25px"></img>
        </div>
      </div>
      <MobileNavigation cycleOpen={cycleOpen} open={open}></MobileNavigation>
    </div>
  );
};

export default Header;
