import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { motion, useIsPresent } from "framer-motion";
import tp from "../assets/images/tp-1.jpg";
import arrow from "../assets/images/arrow-left.svg";
import { EffectCreative, Mousewheel } from "swiper";
import { Link } from "react-router-dom";
import { getProjectsByType } from "../services/Api";
import Loading from "../essentials/Loading";
const Interior = () => {
  const isPresent = useIsPresent();
  const [index, setIndex] = useState(0);
  const [visibility, setVisibility] = useState("visible");
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    setIsLoading(true);
    let response = await getProjectsByType(2);
    setProjects(response);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="2xl:container mx-auto pt-[67px] lg:pt-[93.86px] overflow-hidden relative">
      <div className="absolute pt-4 p-1 px-3 lg:p-3 flex items-center top-[47.43px] lg:top-[93.86px] w-full z-30 bg-[#222222]">
        <Link to={"/projects"}>
          <div className=" hover:bg-gray-700 active:bg-gray-800 p-2 transition-all duration-200 ease-in-out cursor-pointer">
            <img src={arrow}></img>
          </div>
        </Link>
        <div className="mx-1"></div>
        <p className="uppercase text-gray-300 font-bold text-xs leading-3 ">
          All Projects
        </p>
      </div>
      {isLoading ? (
        <div className="h-[calc(100vh-93.86px)] min-h-[-webkit-fill-available] relative z-50 flex flex-col justify-center">
          <Loading></Loading>
        </div>
      ) : (
        <Swiper
          direction={"vertical"}
          watchSlidesProgress
          centeredSlides
          spaceBetween={0}
          slidesPerView={1}
          mousewheel={true}
          modules={[Mousewheel, EffectCreative]}
          preventInteractionOnTransition
          speed={1000}
          // allowTouchMove={true}
          breakpoints={{
            0: {
              allowTouchMove: true,
              allowSlideNext: true,
              allowSlidePrev: true,
            },
            1440: {
              allowTouchMove: false,
              allowSlideNext: true,
              allowSlidePrev: true,
            },
          }}
          // className='h-[calc(100vh-93.86px)]'
          className="h-[calc(100vh-93.86px)] min-h-[-webkit-fill-available] relative"
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
        >
          {projects.map((data) => (
            <SwiperSlide className="px-4 h-full">
              <div className="flex flex-col lg:flex-row h-[calc(100vh-130px)] items-center justify-start lg:justify-evenly mt-[52px] lg:mt-0">
                <div className="w-full lg:w-1/2 flex flex-col">
                  <h2 className="text-primary text-xl lg:text-3xl uppercase font-bold">
                    {data.project_name}
                  </h2>
                  {/* <div className='my-4'></div> */}
                  <p className="text-light text-sm my-4">
                    {data.project_description}
                  </p>

                  <div className="p-3 lg:p-4  justify-around w-full hidden lg:flex">
                    <div className="flex flex-col items-center w-[30%]">
                      <p className="uppercase text-primary font-bold text-[10px] leading-3">
                        Location
                      </p>
                      <p
                        className={`transition-all duration-300 ease-in-out text-xs lg:text-lg leading-6 text-light `}
                      >
                        {data.project_address}
                      </p>
                    </div>
                    <div className="border-x border-x-primary w-0"></div>
                    <div className="flex flex-col items-center  w-[30%]">
                      <p className="uppercase text-primary font-bold text-[10px] leading-3">
                        Area
                      </p>
                      <p
                        className={`transition-all duration-300 ease-in-out text-xs lg:text-lg leading-6 text-light `}
                      >
                        {data.project_area}
                      </p>
                    </div>
                    <div className="border-x border-x-primary w-0"></div>
                    <div className="flex flex-col items-center  w-[30%]">
                      <p className="uppercase text-primary font-bold text-[10px] leading-3">
                        Year
                      </p>
                      <p
                        className={`transition-all duration-300 ease-in-out text-xs lg:text-lg leading-6 text-light `}
                      >
                        {data.project_date.substring(0, 4)}
                      </p>
                    </div>
                  </div>
                  <Link to={"/project/" + data.project_id} className="my-4">
                    <p className="text-primary  font-bold">Learn More</p>
                  </Link>
                </div>
                <div className="mx-8"></div>
                <div className="flex justify-end items-start w-full lg:w-1/2">
                  <img
                    src={
                      "https://api.racengineeringlb.com/storage/app/public/" +
                      data.project_thumbnail_path
                    }
                    className=""
                  ></img>
                </div>
                <div className="py-5 lg:p-4  justify-between w-full flex lg:hidden">
                  <div className="flex flex-col items-center w-[30%]">
                    <p className="uppercase text-primary font-bold text-[10px] leading-3">
                      Client
                    </p>
                    <p
                      className={`transition-all duration-300 ease-in-out text-xs lg:text-lg leading-6 text-light `}
                    ></p>
                  </div>
                  <div className="border-x border-x-primary w-0"></div>
                  <div className="flex flex-col items-center  w-[30%]">
                    <p className="uppercase text-primary font-bold text-[10px] leading-3">
                      Industry
                    </p>
                    <p
                      className={`transition-all duration-300 ease-in-out text-xs lg:text-lg leading-6 text-light `}
                    ></p>
                  </div>
                  <div className="border-x border-x-primary w-0"></div>
                  <div className="flex flex-col items-center  w-[30%]">
                    <p className="uppercase text-primary font-bold text-[10px] leading-3">
                      Year
                    </p>
                    <p
                      className={`transition-all duration-300 ease-in-out text-xs lg:text-lg leading-6 text-light `}
                    ></p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
    </div>
  );
};

export default Interior;
