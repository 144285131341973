import React, { useEffect, useState } from "react";
import pin from "../assets/images/location.svg";
import expand from "../assets/images/expand.svg";
import calendar from "../assets/images/calendar.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import Loading from "../essentials/Loading";
import "swiper/css";
import { motion, useIsPresent } from "framer-motion";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { getProjectByID } from "../services/Api";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
const SingleProject = () => {
  const params = useParams();
  const isPresent = useIsPresent();
  const [projectID, setProjectID] = useState(null);
  const [project, setProject] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(1);
  const getData = async () => {
    setIsLoading(true);
    let response = await getProjectByID(projectID);
    setProject(response.project);
    setImages(JSON.parse(response.images));
    setIsLoading(false);
  };

  useEffect(() => {
    setProjectID(params.project_id);
    console.log(projectID);
  }, [params.project_id]);
  useEffect(() => {
    if (projectID != null) getData();
  }, [projectID]);

  return (
    <div>
      <div className="2xl:container mx-auto grid grid-cols-1 lg:grid-cols-2 pt-[57.43px] lg:pt-[93.86px]">
        {isLoading ? (
          <div className="md:h-[500px] flex flex-col justify-center">
            <Loading></Loading>
          </div>
        ) : (
          <img
            src={
              "https://racengineeringlb.s3.me-south-1.amazonaws.com/" +
              project.project_thumbnail_path
            }
            className="w-full md:h-[500px]"
          ></img>
        )}
        <div className="mx-4 lg:mx-12 flex flex-col justify-center">
          {isLoading ? (
            <Skeleton sx={{ bgcolor: "#bcb8b1" }} variant="rectangular" />
          ) : (
            <h1 className="uppercase font-bold text-[#CB9831] text-xl lg:text-3xl leading-6 lg:mt-0 mt-8">
              {project.project_name}
            </h1>
          )}
          {isLoading ? (
            <Skeleton
              sx={{ bgcolor: "#bcb8b1", marginTop: "16px" }}
              height="100px"
              variant="rectangular"
            />
          ) : (
            <p className="text-light text-justify mt-4">
              {project.project_description}
            </p>
          )}

          <div className="my-5"></div>
          <div className="grid gap-8 lg:gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-between">
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "#bcb8b1" }}
                height="50px"
                variant="rectangular"
              />
            ) : (
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <img src={pin} width="30px"></img>
                <div className="m-1"></div>
                <p className="uppercase text-white text-center text-sm">
                  {project.project_address} <br></br> {project.project_country}
                </p>
              </div>
            )}

            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "#bcb8b1" }}
                height="50px"
                variant="rectangular"
              />
            ) : (
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <img src={expand} width="30px"></img>
                <div className="m-1"></div>
                <p className="uppercase text-white  text-sm">
                  {project.project_area} sqm
                </p>
              </div>
            )}

            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "#bcb8b1" }}
                height="50px"
                variant="rectangular"
              />
            ) : (
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <img src={calendar} width="30px"></img>
                <div className="m-1"></div>
                <p className="uppercase text-white  text-sm">
                  {project.project_date.substring(0, 4)}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="my-[50px] lg:my-[100px]"></div>
      <div className="">
        <div className="2xl:container mx-auto">
          <div className="text-center">
            <h4 className="uppercase font-bold text-light leading-3 text-lg">
              {index}/{images.length}
            </h4>
            {/* <h1 className='uppercase font-bold text-primary text-xl lg:text-2xl leading-4'>Stairway</h1> */}
          </div>
        </div>
        <div className="my-[50px]"></div>
        <div className="2xl:container mx-auto">
          <Swiper
            breakpoints={{
              0: {
                spaceBetween: 10,
                slidesPerView: 1.25,
              },
              540: {
                spaceBetween: 20,
                slidesPerView: 1.5,
              },
              1024: {
                spaceBetween: 30,
              },
              1440: {
                spaceBetween: 50,
              },
            }}
            pagination={{
              el: ".my-pagination",
              clickable: true,
              renderBullet: (index, className) => {
                return '<span class="compass ' + className + '">' + "</span>";
              },
            }}
            centeredSlides
            loop
            spaceBetween={50}
            slidesPerView={1.5}
            onSlideChange={(e) => setIndex(e.realIndex + 1)}
            modules={[Pagination]}
          >
            {isLoading ? (
              <Loading></Loading>
            ) : (
              images.map((data) => (
                <SwiperSlide className="h-full">
                  <img
                    src={
                      "https://racengineeringlb.s3.me-south-1.amazonaws.com/" +
                      data
                    }
                    className="w-full h-full"
                  ></img>
                </SwiperSlide>
              ))
            )}
            <div className="my-pagination text-center" />
          </Swiper>
        </div>
      </div>

      <div className="my-[50px] lg:my-[100px]"></div>

      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen flex flex-col justify-center h-full"
      />
    </div>
  );
};

export default SingleProject;
